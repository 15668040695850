<template>
  <div></div>
</template>

<script>
import constants from '@/common/constants'

export default {
  name: 'logout',

  created() {
    delete localStorage['local-token']
    this.$store.commit('auth/SET_AUTH', '')
    localStorage.setItem(constants.TOKEN_KEY, '')
    localStorage.setItem(constants.LOGOUT_KEY, Date.now().toString())
    this.$router.push({ name: 'login' })
  },
}
</script>
